import "./App.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import { PreBuiltUIList, SuperTokensConfig, ComponentWrapper } from "./config";
import { EmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/emailpassword";
import Session from 'supertokens-auth-react/recipe/session';
import axios from 'axios';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

SuperTokens.init(SuperTokensConfig);

function App() {

    function redirectToSource(source:string,token:string) {
        let redUrl;
        if (source === 'agentSouk') {
            redUrl = process.env.REACT_APP_AGENTSOUQ_SUPERTOKEN_URL + token || '';
        }
        if (source === 'crm') {
            redUrl = process.env.REACT_APP_CRM_SUPERTOKEN_URL + token || '';
        }

        // link.target = "_blank"
        var link = document.createElement("a")
        if(redUrl){
            link.href = redUrl
            link.click();
            window.location.href = redUrl;
        }
        return true;
    }
    
    useEffect(() => {
        setTimeout(async () => {
            var location = window.location;
            if (location.pathname === '/login_by_token') {
                var source = getUrlParamVal('source');
                var token = getUrlParamVal('token');
                if (source && token) {
                    const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/signin/token', {
                        token
                    }); 
                    if (response.data.success) {
                        Session.addAxiosInterceptors(axios)
                        Session.doesSessionExist(); 
                        redirectToSource(source,token)
                    }
                }
            }

            if (location.pathname === '/auth/callback/google/crm' || location.pathname === '/auth/callback/google/agentSouk') {
                const redirect_url = window.location.origin + window.location.pathname;
                const pathParts = location.pathname.split('/');
                const lastPartOfPathName =  pathParts[pathParts.length - 1].replace(/^\/|\/$/g, '');
                const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/signinup',
                    {
                        thirdPartyId: 'google',
                        redirectURIInfo: {
                            redirectURIOnProviderDashboard:redirect_url,
                            redirectURIQueryParams:{
                                authuser: "2",
                                code: getUrlParamVal('code'),
                                prompt: getUrlParamVal('prompt'),
                                scope: getUrlParamVal('scope'),
                                state: 'eyJzdGF0ZSI6ImRmN2ZmOTY3OWUzZDExZjcxNjY4OSJ9'
                            }
                        }
                    },
                    {
                    headers: {
                        'rid': 'thirdparty'
                    }
                }).then((resp)=>{
                    Session.addAxiosInterceptors(axios)
                    Session.doesSessionExist();
                    redirectToSource(lastPartOfPathName,resp.data.user.id)
                });
            }
        }, 1500);
    });

    return (
        <SuperTokensWrapper>
            <EmailPasswordComponentsOverrideProvider
                components={{
                    EmailPasswordSignUpForm_Override: ({ DefaultComponent, ...props }) => {
                        return (
                            <DefaultComponent
                                {...props}
                                formFields={[
                                    props.formFields.find(({ id }) => id === 'first_name')!,
                                    props.formFields.find(({ id }) => id === 'email')!,
                                    props.formFields.find(({ id }) => id === 'last_name')!,
                                    props.formFields.find(({ id }) => id === 'password')!,
                                    props.formFields.find(({ id }) => id === 'company_name')!,
                                ]}


                            />
                        );
                    },
                }}>
                {/* Rest of the JSX */}
            </EmailPasswordComponentsOverrideProvider>
            <ComponentWrapper>
                <div className="App app-container">
                    <Router>
                        <div className="fill">
                            <Routes>
                                {/* This shows the login UI on "/auth" route */}
                                {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)}
                                <Route
                                    path="/"
                                    element={
                                        <SessionAuth>
                                            <Home />
                                        </SessionAuth>
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
                </div>
            </ComponentWrapper>
        </SuperTokensWrapper>
    );
}

function getUrlParamVal(paramName: string) {
    const extractedParams: { [key: string]: string } = {};
    const decodedValue = decodeURIComponent(window.location.search);
    const paramsArray = decodedValue.split('&');
    paramsArray.forEach(param => {
        const [title, value] = param.split('=');
        const filteredTitle = title.replace('?', '');
        extractedParams[filteredTitle] = value;
    })
    return extractedParams[paramName];
}

export default App;
